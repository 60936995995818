<script lang="ts" setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const props = withDefaults(defineProps<Props>(), {
  location: 'bottom end',
  badgeProps: undefined,
})

const emit = defineEmits<Emit>()
const isMenu = ref(false)

interface Props {
  entries: []
  chats: []
  badgeProps?: unknown
  location?: any
}
interface Emit {
  (e: 'read', chatIds: number[], entryIds: number[]): void
  (e: 'remove', id: number, type: string): void
  (e: 'click', id: number, type: string): void
}

const markAllRead = () => {
  const chatIds = props.chats.map(item => item.id)
  const entryIds = props.entries.map(item => item.id)

  emit('read', chatIds, entryIds)
}

const totalUnseenNotifications = computed(() => {
  return (props.chats ? props.chats.length : 0) + (props.entries ? props.entries.length : 0)
})
</script>

<template>
  <VMenu
    v-model="isMenu"
    width="380px"
    activator="parent"
    :location="props.location"
    offset="14px"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <IconBtn
        id="notification-btn"
        class="mr-2"
      >
        <VBadge
          v-bind="props.badgeProps"
          :model-value="totalUnseenNotifications > 0"
          color="error"
          :content="totalUnseenNotifications"
          class="notification-badge"
        >
          <VIcon
            size="26"
            icon="tabler-bell"
          />
        </VBadge>
      </IconBtn>
    </template>

    <VCard class="d-flex flex-column">
      <!-- 👉 Header -->
      <VCardItem class="notification-section">
        <VCardTitle class="text-lg">
          通知
        </VCardTitle>

        <template #append>
          <IconBtn
            v-show="(props.chats.length || props.entries.length)"
            @click="markAllRead"
          >
            <VIcon icon="tabler-mail-opened" />

            <VTooltip
              activator="parent"
              location="start"
            >
              すべて既読にする
            </VTooltip>
          </IconBtn>
        </template>
      </VCardItem>

      <VDivider />

      <!-- 👉 Notifications list -->
      <PerfectScrollbar
        :options="{ wheelPropagation: false }"
        style="max-block-size: 23.75rem;"
      >
        <VList
          lines="three"
          class="notification-list rounded-0 py-0"
        >
          <VListSubheader
            v-show="props.chats.length"
            inset
          >
            <h4>お問い合わせ</h4>
          </VListSubheader>
          <VListItem
            v-for="notification in props.chats"
            :key="notification.id"
            link
            lines="one"
            min-height="66px"
            class="list-item-hover-class"
            @click="$emit('click', notification.id, 'chat'), isMenu = false"
          >
            <template #prepend>
              <VListItemAction start>
                <VChip
                  label
                  color="primary"
                >
                  {{ notification.status === '未対応' ? '対応中' : notification.status }}
                </VChip>
              </VListItemAction>
            </template>

            <VListItemTitle>{{ notification.categories ? notification.categories.name : '' }}</VListItemTitle>
            <span class="text-xs text-disabled">{{ $dayjs(notification.updated_at).format('YYYY年MM月DD日 (dd) HH時mm分') }}</span>

            <template #append>
              <div class="d-flex flex-column align-center gap-4">
                <VBadge
                  color="error"
                  inline
                  :content="notification.count"
                />
              </div>
            </template>
          </VListItem>

          <VDivider
            v-show="props.entries.length"
            inset
          />
          <VListSubheader
            v-show="props.entries.length"
            inset
          >
            <h4>進捗</h4>
          </VListSubheader>

          <VListItem
            v-for="notification in props.entries"
            :key="notification.id"
            link
            lines="one"
            min-height="66px"
            class="list-item-hover-class"
            @click="$emit('click', notification.id, 'entry'), isMenu = false"
          >
            <template #prepend>
              <VListItemAction start>
                <VChip
                  label
                  color="primary"
                >
                  {{ notification.progress }}
                </VChip>
              </VListItemAction>
            </template>

            <VListItemTitle>{{ notification.facility ? notification.facility.name : '' }}</VListItemTitle>
            <span class="text-xs text-disabled">{{ `${$dayjs(notification.event_date_from).format("M月D日")}~${$dayjs(notification.event_date_to).format("M月D日")}` }}</span>

            <template #append>
              <div class="d-flex flex-column align-center gap-4">
                <VBadge
                  color="error"
                  inline
                  :content="notification.count"
                />
              </div>
            </template>
          </VListItem>

          <VListItem
            v-show="props.chats.length === 0 && props.entries.length === 0"
            class="text-center text-medium-emphasis"
            style="block-size: 56px;"
          >
            <VListItemTitle>通知が見つかりませんでした</VListItemTitle>
          </VListItem>
        </VList>
      </PerfectScrollbar>

      <!-- 👉 Footer -->
      <VCardActions class="notification-footer" />
    </VCard>
  </VMenu>
</template>

<style lang="scss">
.notification-section {
  padding: 14px !important;
}

.notification-footer {
  padding: 6px !important;
}

.list-item-hover-class {
  .visible-in-hover {
    display: none;
  }

  &:hover {
    .visible-in-hover {
      display: block;
    }
  }
}

.notification-list.v-list {
  .v-list-item {
    border-radius: 0 !important;
    margin: 0 !important;
  }
}

// Badge Style Override for Notification Badge
.notification-badge {
  .v-badge__badge {
    /* stylelint-disable-next-line liberty/use-logical-spec */
    min-width: 18px;
    padding: 0;
    block-size: 18px;
  }
}
</style>
