<script lang="ts" setup>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

const props = withDefaults(defineProps<Props>(), {
  location: 'bottom end',
  badgeProps: undefined,
})

const emit = defineEmits<Emit>()
const router = useRouter()
const isMenu = ref(false)

const notificationStore = useNotificationStore()

interface Props {
  owner: []
  badgeProps?: unknown
  location?: any
}
interface Emit {
  (e: 'read', chatIds: number[], entryIds: number[]): void
  (e: 'remove', id: number, type: string): void
  (e: 'click', id: number, type: string): void
}

const showNotification = (data: any) => {
  if (data.status === 'イチオシ') {
    notificationStore.campaignId = data.person
    router.push({ name: 'admins-pickup' })
  }

  else { emit('click', data.id, 'owner') }

  isMenu.value = false
}

const totalUnseenNotifications = computed(() => {
  return props.owner ? props.owner.length : 0
})

const chats = computed(() => {
  return props.owner ? props.owner.filter((f: any) => f.entry_id === null) : []
})

const entries = computed(() => {
  return props.owner ? props.owner.filter((f: any) => f.entry_id !== null) : []
})
</script>

<template>
  <VMenu
    v-model="isMenu"
    width="380px"
    activator="parent"
    :location="props.location"
    offset="14px"
    :close-on-content-click="false"
  >
    <template #activator="{ props }">
      <IconBtn
        id="notification-btn"
        class="mr-2"
      >
        <VBadge
          v-bind="props.badgeProps"
          :model-value="totalUnseenNotifications > 0"
          color="error"
          :content="totalUnseenNotifications"
          class="notification-badge"
        >
          <VIcon
            size="26"
            icon="tabler-bell"
          />
        </VBadge>
      </IconBtn>
    </template>

    <VCard class="d-flex flex-column">
      <!-- 👉 Header -->
      <VCardItem class="notification-section">
        <VCardTitle class="text-lg">
          通知
        </VCardTitle>
      </VCardItem>

      <VDivider />

      <!-- 👉 Notifications list -->
      <PerfectScrollbar
        :options="{ wheelPropagation: false }"
        style="max-block-size: 23.75rem;"
      >
        <VList
          lines="three"
          class="notification-list rounded-0 py-0"
        >
          <VListSubheader
            v-show="chats.length"
            inset
          >
            <h4>お問い合わせ</h4>
          </VListSubheader>
          <VListItem
            v-for="notification in chats"
            :key="notification.id"
            link
            lines="one"
            min-height="66px"
            class="list-item-hover-class"
            @click="showNotification(notification), isMenu = false"
          >
            <template #prepend>
              <VListItemAction start>
                <VChip
                  label
                  color="primary"
                >
                  {{ notification.status === '未対応' ? '対応中' : notification.status }}
                </VChip>
              </VListItemAction>
            </template>

            <VListItemTitle>{{ notification.title }}</VListItemTitle>
            <span class="text-xs text-disabled">{{ $dayjs(notification.updated_at).format('YYYY年MM月DD日 (dd) HH時mm分') }}</span>

            <template #append>
              <div class="d-flex flex-column align-center gap-4">
                <VBadge
                  color="error"
                  inline
                  :content="notification.count"
                />
              </div>
            </template>
          </VListItem>

          <VDivider
            v-show="entries.length"
            inset
          />
          <VListSubheader
            v-show="entries.length"
            inset
          >
            <h4>進捗</h4>
          </VListSubheader>

          <VListItem
            v-for="notification in entries"
            :key="notification.id"
            link
            lines="one"
            min-height="66px"
            class="list-item-hover-class"
            @click="showNotification(notification), isMenu = false"
          >
            <template #prepend>
              <VListItemAction start>
                <VChip
                  label
                  color="primary"
                >
                  {{ notification.status === '未対応' ? '対応中' : notification.status }}
                </VChip>
              </VListItemAction>
            </template>

            <VListItemTitle>{{ notification.title }}</VListItemTitle>
            <span class="text-xs text-disabled">{{ $dayjs(notification.updated_at).format('YYYY年MM月DD日 (dd) HH時mm分') }}</span>

            <template #append>
              <div class="d-flex flex-column align-center gap-4">
                <VBadge
                  color="error"
                  inline
                  :content="notification.count"
                />
              </div>
            </template>
          </VListItem>

          <VListItem
            v-show="props.owner.length === 0"
            class="text-center text-medium-emphasis"
            style="block-size: 56px;"
          >
            <VListItemTitle>通知が見つかりませんでした</VListItemTitle>
          </VListItem>
        </VList>
      </PerfectScrollbar>

      <!-- 👉 Footer -->
      <VCardActions class="notification-footer" />
    </VCard>
  </VMenu>
</template>

<style lang="scss">
.notification-section {
  padding: 14px !important;
}

.notification-footer {
  padding: 6px !important;
}

.list-item-hover-class {
  .visible-in-hover {
    display: none;
  }

  &:hover {
    .visible-in-hover {
      display: block;
    }
  }
}

.notification-list.v-list {
  .v-list-item {
    border-radius: 0 !important;
    margin: 0 !important;
  }
}

// Badge Style Override for Notification Badge
.notification-badge {
  .v-badge__badge {
    /* stylelint-disable-next-line liberty/use-logical-spec */
    min-width: 18px;
    padding: 0;
    block-size: 18px;
  }
}
</style>
