<script lang="ts" setup>
import ChatPopup from '@/views/chat/Popup.vue'

const userData = useCookie<any>('userData')
const isChatVisible = ref(false)
const selectedChat = ref(null)
const url = ref('')

const chatStore = useClientStoreChatStore()
const entryStore = useEntryStore()
const ownerChatStore = useOwnerChatStore()
const entryChatStore = useEntryChatStore()
const notificationStore = useNotificationStore()

const showPopup = (id: number, type: string) => {
  if (type === 'chat') {
    chatStore.fetch(id).then(response => {
      selectedChat.value = response
      url.value = '/client_store_chat_messages'
      isChatVisible.value = true

      if (userData.value.role === 'client' && (userData.value.user_id === selectedChat.value?.client_store_id)) {
        chatStore.setClientReaded(id)
        notificationStore.chat.forEach((item, index) => {
          if (id === item.id)
            notificationStore.chat.splice(index, 1)
        })
      }
    })
  }
  else if (type === 'owner') {
    ownerChatStore.fetch(id).then(response => {
      selectedChat.value = response
      url.value = '/owner_chat_messages'
      isChatVisible.value = true
    })
  }
  else {
    entryChatStore.fetchWithEntryId(id).then(response => {
      selectedChat.value = response
      url.value = '/entry_chat_messages'
      isChatVisible.value = true

      if (userData.value.role === 'client' && (userData.value.user_id === selectedChat.value?.client_store.id)) {
        entryStore.setReadedChat(id)
        notificationStore.entry.forEach((item, index) => {
          if (id === item.id)
            notificationStore.entry.splice(index, 1)
        })
      }
    })
  }
}

const updatedData = (updateSelected: any) => {
  selectedChat.value = updateSelected
}

watchEffect(() => {
  if (userData.value) {
    if (userData.value.role === 'owner')
      notificationStore.fetchesOwner()
    else
      notificationStore.fetches(userData.value.role === 'admin')
  }
})

const removeNotification = (notificationId: number, type: string) => {
  if (type === 'chat') {
    chatStore.setClientReaded(notificationId)
    notificationStore.chat.forEach((item, index) => {
      if (notificationId === item.id)
        notificationStore.chat.splice(index, 1)
    })
  }
  else {
    entryStore.setReadedChat(notificationId)
    notificationStore.entry.forEach((item, index) => {
      if (notificationId === item.id)
        notificationStore.entry.splice(index, 1)
    })
  }
}

const markRead = (chatIds: number[], entryIds: number[]) => {
  chatIds.forEach(id => {
    chatStore.setClientReaded(id)
  })

  notificationStore.chat = []

  entryIds.forEach(id => {
    entryStore.setReadedChat(id)
  })

  notificationStore.entry = []
}

watch([notificationStore.entry, notificationStore.chat, notificationStore.owner], () => {
  navigator.setAppBadge(notificationStore.entry.length + notificationStore.chat.length + notificationStore.owner.length)
})

onMounted(() => {
  if ((notificationStore.entry.length + notificationStore.chat.length + notificationStore.owner.length) > 0)
    navigator.setAppBadge(notificationStore.entry.length + notificationStore.chat.length + notificationStore.owner.length)
})
</script>

<template>
  <div>
    <NotificationsAdmin
      v-if="userData.role === 'admin'"
      :entries="notificationStore.entry"
      :chats="notificationStore.chat"
      :owner="notificationStore.owner"
      @remove="removeNotification"
      @read="markRead"
      @click="showPopup"
    />
    <NotificationsOwner
      v-if="userData.role === 'owner'"
      :owner="notificationStore.owner"
      @remove="removeNotification"
      @read="markRead"
      @click="showPopup"
    />
    <Notifications
      v-if="userData.role === 'client'"
      :entries="notificationStore.entry"
      :chats="notificationStore.chat"
      @remove="removeNotification"
      @read="markRead"
      @click="showPopup"
    />
    <ChatPopup
      v-if="selectedChat"
      v-model:isChatVisible="isChatVisible"
      v-model:selected="selectedChat"
      :url="url"
      @updated-data="updatedData"
    />
  </div>
</template>
